<template>
  <div ref="videoPlayer" class="text-center" style="position:relative;cursor:pointer;" @click="loadViewFrame()">
    <v-icon size="100" color="Z" style="position:absolute;left:50%;top:50%;margin-top:-50px; margin-left:-50px;">fa-play</v-icon>
    <img :src="`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`" width="640" height="360" style="max-width:100%;"/>
  </div>
</template>

<script>
import navData from '@/data/nav.json'

export default {
  name: "VideoEmbed",
  props: {
    videoId: String,
  },
  data: function() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    loadViewFrame() {
      var iframe = document.createElement('iframe');
      iframe.src = `https://www.youtube.com/embed/${this.videoId}?autoplay=1&origin=https://corporatefitness.app`;
      iframe.class = 'full-dialog';
      iframe.style = 'border:0;max-width:100%;height:360px;width:640px;'
      iframe.allowfullscreen = 'allowfullscreen';
      iframe.allow = 'autoplay;fullscreen';
      this.$refs.videoPlayer.textContent = '';
      this.$refs.videoPlayer.appendChild(iframe);
    },
  },
  watch: {
  }
};
</script>
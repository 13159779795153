<template>
  <div class="home">
    <div id="page-hero" class="pt-6 pb-2 pt-md-12 mb-0 white--text container--fluid">
      <div class="container">
        <v-row>
          <v-col cols="12" sm="8">
            <h1 class="display-2 mb-4"><i style="font-weight:normal;">Connected</i><br/>Corporate Fitness</h1>
            <br/>
            <p v-if="false">
              Use {{tenant.name}} to create engaging connected corporate fitness events! Our auto-sync allows your employees to get and stay fit and healthy whilst using their favorite fitness trackers.
            </p>
            <h3 class="headline">
              <!-- Create connected corporate fitness events to get more happy employees. Happy and fit employees are more productive, less stressed, and reduce healthcare costs! -->
              Fit and happy employees are more productive and less stressed.<br/>
              Why wait? Let's Go!
            </h3>
            <p class="mb-0 mt-8">
              <v-btn class="mr-4 mb-4" color="white" :to="{name:'startTrial'}">Start Free Trial</v-btn>
              <v-btn v-if="false" class="mr-4 mb-4" color="white" :to="{name:'eventmanagerCreate'}">Create your event</v-btn>
              <v-btn v-if="false" class="mb-4 font-weight-bold" color="white" text :to="{name: 'contact'}">Contact Us</v-btn>
            </p>
          </v-col>
          <v-col v-if="false" cols="12" sm="4">
            <v-icon v-if="false" color="white" size="100">fa-watch</v-icon>
            <img src="/images/logo-cofi.png" width="300" style="max-width:100%;"/>
          </v-col>
        </v-row>
      </div>
    </div>

    <div class="grey lighten-4">
    <v-container class="pb-6">
      <v-row>
        <v-col class="pb-0">
          <h2 class="title">Inclusive and engaging</h2>
          <p>
            Offering a connected fitness challenge is a great way to organize a corporate wellness program which is both fun and engaging at the same time. 
            Our platform allows you to host inclusive challenges which are motivating all of your workforce, not only the most competitive few!
          </p>
          <p>
            Our leaderboards can be fully customized and show the real-time progress of your challenges. 
            The social result feeds allow your employees to cheer each other up  by giving them likes and comments. 
          </p>
          
          <VideoEmbed videoId="LyoxH7OLMb0" />

          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/get" title="Download our free iPhone or Android app">
                <v-icon size="50" color="grey">fa-mobile</v-icon>
                <h3 class="my-4">iPhone and Android App</h3>
                <p>Download our free iPhone or Android app to track your progress.</p>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/integrations/fitness" title="Connect any of our many supported devices">
                <v-icon size="50" color="grey">fa-watch-fitness</v-icon>
                <h3 class="my-4">9+ Fitness Device Integrations</h3>
                <p>Connect any of our many supported devices for auto-sync from your app or watch.</p>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4">
                <v-icon size="50" color="grey">fa-keyboard</v-icon>
                <h3 class="my-4">Manual Entry</h3>
                <p>Easily enter activities and steps on our website.</p>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/integrations/microsoft-teams" title="Integrate into Microsoft Teams">
                <img height="50px" src="https://sodisp.imgix.net/web/microsoft-teams-logo-sm.png?h=50" class="mb-n2" style="filter:grayscale(1);" alt="Microsoft Teams logo"/>
                <h3 class="my-4">Microsoft Teams</h3>
                <p>Integrate into Microsoft Teams channels and chats for a seamless connected Teams experience.</p>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/integrations/slack" title="Integrate into Slack">
                <v-icon size="50" color="grey">fab fa-slack</v-icon>
                <h3 class="my-4">Slack Integration</h3>
                <p>Integrate into your Slack workspace for a seamless and integrated experience for your team.</p>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/solutions/fitness-engine" title="Easily embed our proven technology stack">
                <v-icon size="50" color="grey">fa-cogs</v-icon>
                <h3 class="my-4">APIs and custom apps</h3>
                <p>Easily embed our proven technology stack in a custom app or platform matching your brand experience.</p>
              </v-card>
            </v-col>
            <!--
            <v-col cols="12" sm="6" md="4">
              <v-card :elevation="1" class="text-center align-center pa-4" to="/integrations/zapier">
                <v-icon size="50" color="grey">fa-asterisk</v-icon>
                <h3 class="my-4">Zapier Integration</h3>
                <p>Integrate into your Slack workspace for a seamless and integrated experience for your team.</p>
              </v-card>
            </v-col>
            -->
          </v-row>
         </v-col>
      </v-row>
    </v-container>
    </div>

    <div class="white py-6">
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="title">For all Step and Fitness Challenges!</h2>
            <p class="">
              Whichever type of fitness challenge you want to start, we've got you covered! 
            </p>
            <v-row>
              <v-col cols="12" md="4">
                <BigButton elevated title="Step Challenges" text="Create your next step challenge on CorporateFitness.app. Users can use their phone or favorite fitness tracker to participate." href="/product/challenges/step-challenges" icon="fa-shoe-prints" />
              </v-col>
              <v-col cols="12" md="4">
                <BigButton elevated title="Time-based Challenges" text="Time-based challenges are a great option to run an inclusive fitness challenge where everyone can participate." href="/product/challenges/time-based-challenges" icon="fa-stopwatch" />
              </v-col>
              <v-col cols="12" md="4">
                <BigButton elevated title="Distance Challenges" text="Distance challenges are a great way to reward your team for moving more. Make every km/mile count!" href="/product/challenges/distance-challenges" icon="fa-ruler" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <p class="mb-0 mt-8">
          <v-btn class="mr-4 mb-4" color="primary" outlined href="/start-trial">Start Free Trial</v-btn>
          <v-btn class="mb-4" color="primary" text href="/product/challenges">More Challenge Types</v-btn>
        </p>
      </div>
    </div>

    <div class="" style="background-color: #008F5822;">
    <v-container class="Zpb-0">
      <v-row class="align-center">
        <v-col cols="12" class="align-left mb-0 pb-0">
          <h3 class="mb-0 pb-0">Quick Price Estimate</h3>
          <p class="mb-0">Get a quick price estimate based on the number of participants you expect. The final price will depend on selected plan and service package.</p>
        </v-col>
        <v-col cols="12" sm="6">
          <v-slider 
            v-model="userCount"
            min="10"
            max="1000"
            thumb-label
            step="10"
            class="grow"
            hide-details
            />
          <p v-if="userCount >= 1000" class="align-left">
            <router-link to="/contact">Request a quote</router-link> for larger events (volume discount may apply).
          </p>
        </v-col>
        <v-col cols="6" sm="3" class="align-center">
          <v-sheet class="dotted pa-4">
            <div class="text-muted">Starting at</div>
            {{userCount * perUserFee}} {{currency}}
            <div class="text-muted">(for {{userCount}} participants)</div>
          </v-sheet>
        </v-col>
        <v-col cols="6" sm="3">
          <v-btn :to="{ name: 'pricing', query: { participants: userCount }}" color="primary">View Pricing &raquo;</v-btn>
        </v-col>
      </v-row>    
    </v-container>
    </div>

    <div class="grey lighten-4">
    <v-container class="pb-0">
      <v-row>
        <v-col class="pb-0">
          <h2 class="title">Integration with all major fitness trackers</h2>
          <SectionIntegrations />
          <p class="mb-0 mt-8 mb-8">
            <v-btn class="mr-4 mb-4" color="primary" outlined href="/features">Explore All Features</v-btn>
            <v-btn class="mb-4" color="primary" outlined href="/contact">Contact Us</v-btn>
          </p>
        </v-col>
      </v-row>
    </v-container>
    </div>
 
    <div class="white py-6">
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="title">Your Brand, Your Event!</h2>
            <p class="">
              Unleash your creativity and customize the app to match your branding guidelines! Change the brand color, add your company logo, and customize the design and content.
            </p>
            <img style="max-width:100%" src="https://strapistoragep.blob.core.windows.net/strapi-uploads/assets/custom_design_examples_92c714bfc7.png"/>
          </v-col>
        </v-row>
        <p class="mb-0 mt-8">
          <v-btn class="mr-4 mb-4" color="primary" outlined href="/product/benefits/full-customization">More on Customization</v-btn>
          <v-btn class="mb-4" color="primary" text href="/start-trial">Start Free Trial</v-btn>
        </p>
      </div>
    </div>

     <div id="page-customers" class="mt-0 blob-bg white--text">
      <div class="container pb-0">
        <v-row>
          <v-col class="pb-4">
            <h1 class="headline">Trusted by over {{ siteData.stats.total_orgs | int }} global corporations</h1>
            <p>
              {{tenant.name}} is used by world-renowned corporations to host their connected fitness events. Some of our key partners are:
            </p>
            <SectionCustomers :showcase="false" corporate/>
          <p class="mb-0 mt-4">
            <strong>{{siteData.stats.total_events | int}}</strong> have been created already by {{siteData.stats.total_orgs | int }} organizations. Join today!
          </p>
          <p class="mb-0 mt-4">
            <v-btn class="mr-4 mb-4" color="white" href="/eventmanager/create">Create Your Event</v-btn>
            <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
          </p>

          </v-col>
        </v-row>
      </div>
    </div>


    <div class="text-center">
      <h2 class="subheader mt-8">Native iOS and Android app</h2>
      <p>
        Download our app for iPhone and Android to track your activities and see the challenge progress in real-time!
      </p>
      <p>
        <v-btn text color="primary" to="/get" title="Download our app for iPhone and Android">Read more</v-btn>
      </p>
      <img class="elevation-12 " style="max-width:100%;" src="https://sodisp.imgix.net/web/app/cofi-app-iphones.png?w=1080&fm=webp" alt="Get our app." /> 
    </div>

    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <h1 class="subtitle">Download our app!</h1>
        </v-col>
        <v-col cols="6" style="display:flex;flex-direction:column;align-items:flex-end;">
          <a class="mr-2" href='https://apps.apple.com/app/connected-corporate-fitness/id1594403776' target="_blank"><img style="max-width:100%;" alt='Download on the App Store' src='https://developer.apple.com/app-store/marketing/guidelines/images/badge-example-preferred.png'/></a>
        </v-col>
        <v-col cols="6">
          <a href='https://play.google.com/store/apps/details?id=app.corporatefitness' target="_blank"><img style="max-width:215px; margin: -15px;" alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
        </v-col>
      </v-row>
    </v-container>

<!-- 
    <v-container class="text-center">
      <img class="elevation-12 " style="max-width:100%;" src="https://sodisp.imgix.net/content/fitness-phone.jpg?w=1080&amp;h=500&amp;fit=fillmax" alt="Create engaging and connected corporate fitness events and challenges." /> 
    </v-container>
 -->
    <v-container v-if="tenant.showFeatures" class="features">
      <v-row>
        <v-col cols=12>
          <h3 class="title">Features </h3>
          <p>Explore the features we offer to <strong>make a success</strong> of your connected fitness event.</p>
          <ul class="check-list">
            <li><router-link to="/integrations/fitness">9+ fitness app and watch integrations</router-link></li>
            <li><router-link to="/get">Get our app for iOS or Android</router-link></li>
            <li><router-link to="/integrations/microsoft-teams">Microsoft Teams integration</router-link></li>
            <li><router-link to="/integrations/slack">Slack integration</router-link></li>
            <li>Easy to use and customizable</li>
            <li>Advanced challenge types available</li>
            <li>Flexible, usage-based <router-link to="/pricing">pricing plans</router-link></li>
            <li><router-link to="/eventmanager/create">Get started</router-link> within minutes</li>
          </ul>

        </v-col>
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-watch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fab fa-apple</v-icon>
          <h4>Auto-sync with Garmin, Fitbit, Apple Watch and more</h4>
          <p>
            Activities tracked with Garmin, Apple Watch, Fitbit, or Polar devices and the MapMyRun app are automatically synced the minute they are saved. Make each breath and every km/mile count!
          </p>
          <p>Don't have a fitness device or app? No worries, we've got you covered as well. Easily add your activities with only a few clicks using our manual entry tool.</p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-stopwatch</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-ruler</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-mountain</v-icon>
          <h4>Any goal, any challenge!</h4>
          <p>
            We support all challenge modes you can think of, plus more! You do running? Check! Yoga? Sure!
          </p>
          <p> 
            Whether your fitness event runs a week, a month, or longer, whether it is a single sport or a multi-sport event, whether it is competitive or inclusive to all, you can host it on {{tenant.name}}.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-map</v-icon>
          <h4>Progress on map</h4>
          <p>
            Make your challenge more fun by mapping the progress on a real map. Simply upload your course map and we'll map the progress on the map!
          </p>
          <p>
            Fun ideas: do a virtual tour around various office locations or draw your logo on the map.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-shield</v-icon>
          <h4>Badges</h4>
          <p>
            Create your own intermediate goals to keep your employees motivated to make it to the (virtual) finish line! 
          </p>
          <p>
            We have plenty different badges available to keep everybody challenged in reaching and improving their fitness.
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-bullseye</v-icon>
          <h4>Be inclusive: Collective progress &amp; Participation Mode</h4>
          <p>
            Let's work together in reaching our common goals!
            Easily set a collective target and everyone's effort will count toward reaching this collective goal!
          </p>
          <p> 
            Enable our participation mode for non-competitive challenges to change the leaderboard into a ticker board where the latest activity 
            is shown at the top to give everyone their moment in the spotlights, regardless of distance, pace, or speed!
          </p>
        </v-col>
        
        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-user</v-icon>
          <span class="plus">+</span>
          <v-icon x-large color="primary">fadl fa fa-users</v-icon>
          <h4>Individual or teams</h4>
          <p>
            What's even more fun than reaching your personal fitness goal? Doing it together with your team or department!
          </p>
          <p>
            {{ tenant.name }} supports advanced team challenges to allow teams and departments to workout together and compete against the other teams and departments.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-paint-roller</v-icon>
          <h4>Customize design &amp; co-branding</h4>
          <p> 
            Make each event truly yours by customizing the design to match your brand. Upload your logo and artwork to make your event look beautiful.
          </p>
          <p>
            Want to take it a step further? We offer a completely co-branded, white-labeled version of the platform which you can host on your own domain.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-cogs</v-icon>
          <h4>Single Sign-On (SSO) and API integrations</h4>
          <p> 
            Connect our platform with your enterprise identity platform using SSO integration to allow your employees to connect using their corporate account.
            Use our REST APIs for custom integration with your systems and services. We provide APIs for various integration scenarios such as automated result or registration data sync. 
            We support iframe embedding for a no-code integration.
            We have Excel import/export options available to help you get started without development work needed.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tools</v-icon>
          <h4>Powerful Management Tools</h4>
          <p> 
            Use our easy to use, yet powerful management tools to manage your virtual challenge and results. All features can be configured using our self-service tools. Need help? No worries, we have extensive knowledge base articles available or can help you with an onboarding trainings session.
          </p>
        </v-col>

        <v-col cols=12 sm=6>
          <v-icon x-large color="primary">fadl fa fa-tags</v-icon>
          <h4>Pricing that scales with your organization</h4>
          <p> 
            Get started with for free and pay only for what you use. See our <router-link to="/pricing">pricing</router-link> for details.
          </p>
        </v-col>
      </v-row>
    </v-container>
 
    <div class="green white--text py-6 py-md-12">
      <div class="container">
        <v-row>
          <v-col cols="12" md="12">
            <h2 class="subtitle">Go Live Today!</h2>
            <h1 class="subtitle">Create your connected corporate fitness event within minutes!</h1>
            <p class="mb-0 mt-4">
              <v-btn class="mr-4 mb-4" color="white" :to="{name:'startTrial'}">Start Free Trial</v-btn>
              <v-btn v-if="false" class="mr-4 mb-4" color="white" href="/eventmanager/create">Create Your Event</v-btn>
              <v-btn class="mb-4" color="white" outlined href="/contact">Contact Us</v-btn>
            </p>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
  
</template>

<script>
import axios from "axios";
import EventGrid from "@/components/EventGrid.vue";
import SectionIntegrations from "@/components/SectionIntegrations.vue";
import SectionCustomers from "@/components/SectionCustomers.vue";
import VideoEmbed from "@/components/VideoEmbed";
import BigButton from "@/components/BigButton";
import siteData from '@/data/site.json'
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "home",
  components: {
    EventGrid,
    SectionIntegrations,
    SectionCustomers,
    VideoEmbed,
    BigButton,
  },
  data() {
    return {
      tenant: tenant,
      siteData: siteData,
      currency: 'USD',
      userCount: 10,
    };
  },
  async mounted() {

  }, 
  computed: {
    perUserFee() {
      var plan = this.siteData.products.find(x=>x.key=='product-event-basic')
      if (!plan) {
        return null;
      }
      return plan.prices[this.currency];
    },
  }
};
</script>

<style lang="scss" scoped>
  .features {
    i { background-color: #008F5822; border-radius: 50%; padding: 15px; margin-top: 15px; }
    .plus {
      margin: 0 20px; line-height: 30px;
      color: #008F58;
    }
    h4 { margin-top: 8px; }
  }

  /* CLS improvements:  */
  #page-hero { min-height: 358px;}
  #page-customers { min-height: 340px;}
  #page-integrations { min-height: 436px;}
  .cta-bar { min-height: 332px;}

  .small { font-size: 75%; display: inline-block; text-align: left;}
  .large { font-size: 200%;}
  .large + .small { margin-left: 5px; }
  .v-sheet.dotted, button.dotted { border: 2px dotted #ccc; border-radius: 4px; background: white; }

  @media (max-width: 500px) {
    /*#page-hero { min-height: 750px;}*/
    #page-customers { min-height: 560px;}
    #page-integrations { min-height: 625px;}
    .cta-bar { min-height: 332px;}
  }</style> 